import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import { useEffect, useState } from "react";
import FormControl from "react-bootstrap/FormControl";
import { useDispatch, useSelector } from "react-redux";
import { default as Constants } from "../../constant";
import { getAllSprint } from "../../redux/actions/sprint.action";
import { getAllUserInfo } from "../../redux/actions/userInfo.action";
import { IUserInfo } from "../../shared/models/userInfo";
import { roleForPoint } from "../../shared/utils";
import { getDate } from "../../util";
import LoadingWidget from "../LoadingWidget";
import UpdateTimeAttendanceModal from "../dashboard/time-attendance-widget/update-time-attendance";
import DashboardUsersFilter from "../dashboard/users-filter/UserFilterWidget";
import "./styles.scss";
import WorkingTimeTable from "./working-time-table";
const WorkingTime = () => {
    const dispatch = useDispatch();
    const webReducer = useSelector((state: any) => state.webReducer);
    const getPreMonth = () => {
        let now = new Date();
        let month = now.getMonth();
        return new Date(now.setMonth(month - 1));
    };
    const [filteredUserIDs, setFilteredUserIDs] = useState<string[]>([]);
    const [startTime, setStartTime] = useState(getDate(getPreMonth()));
    const [endTime, setEndTime] = useState(getDate(new Date()));
    // const [isLoading, setIsLoading] = useState(true);
    const loading = useSelector((state: any) => state.workingTime.loading);
    const updateFilterUserIDs = (userID: string) => {
        if (filteredUserIDs.indexOf(userID) > -1) {
            setFilteredUserIDs(filteredUserIDs.filter((id: any) => id !== userID));
        } else {
            setFilteredUserIDs(filteredUserIDs.concat(userID));
        }
    };
    useEffect(() => {
        dispatch(getAllSprint());
        dispatch(getAllUserInfo());
    }, []);
    useEffect(() => {
        if (webReducer.reload) {
            window.location.reload();
        }
    }, [webReducer.reload]);
    // const history = useHistory();
    const [showUploadWorkingTime, setShowUploadWorkingTime] = useState(false);
    return (
        <Container maxWidth="lg" className="working-time">
            {loading && <LoadingWidget />}
            <div className="flex" style={{ justifyContent: "space-between" }}>
                <div style={{ marginBottom: 12 }}>
                    <Button
                        className="working-time-manager-btn"
                        variant="contained"
                        onClick={() => setShowUploadWorkingTime(true)}
                    >
                        Cập nhật dữ liệu máy chấm công
                    </Button>
                    {/* <Button
                        style={{
                            marginLeft: 12,
                        }}
                        variant="contained"
                        onClick={() =>
                            history.push({
                                pathname: `/time-attendance`,
                            })
                        }
                    >
                        Dữ liệu máy chấm công
                    </Button> */}
                </div>
            </div>

            {showUploadWorkingTime && (
                <UpdateTimeAttendanceModal
                    setUpdateWorkingTimeModalVisible={setShowUploadWorkingTime}
                    startTime={startTime}
                    endTime={endTime}
                />
            )}
            <hr />
            <div className="filter" style={{ marginBottom: 12 }}>
                <div>
                    <FormControl
                        type="date"
                        name="startTime"
                        value={startTime}
                        max={endTime}
                        onChange={(e) => setStartTime(e.target.value)}
                    />
                </div>
                <span className="ml-20">&#126;</span>
                <div>
                    <FormControl
                        type="date"
                        name="endTime"
                        value={endTime}
                        min={startTime}
                        onChange={(e) => setEndTime(e.target.value)}
                    />
                </div>
            </div>
            <div style={{ marginBottom: 12 }}>
                <DashboardUsersFilter
                    currentUserIds={filteredUserIDs}
                    onSelectedUser={updateFilterUserIDs}
                    isFilterTask={false}
                    showTooltip={false}
                    filterUsers={(user: IUserInfo) => roleForPoint(user.role) && user.status == Constants.STATUS_PUBLIC}
                />
            </div>
            <div className="documentations">
                <WorkingTimeTable
                    // setIsLoading={setIsLoading}
                    startTime={startTime}
                    endTime={endTime}
                    filteredUserIDs={filteredUserIDs}
                />
            </div>
        </Container>
    );
};
export default WorkingTime;
