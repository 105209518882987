import Grid from "@material-ui/core/Grid";
import ButtonMUI from "@mui/material/Button";
import axios from "axios";
import moment from "moment";
import { FC, memo, useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import ModalFooter from "react-bootstrap/ModalFooter";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getChildTasks } from "..";
// import { deleteCommentSuccess, updateCommentSuccess } from "../../../../redux/actions/comment.action";
import {
    deleteTaskAction,
    pendingProcessingTaskUpdate,
    updateActorChangeTask,
    updateTask,
    updateTaskFailed,
} from "../../../../redux/actions/task.action";
import { MY_SELF } from "../../../../redux/reducers/task.reducer";
import Config from "../../../../shared/models/config";
import { IProject } from "../../../../shared/models/project";
import { ISprint } from "../../../../shared/models/sprint";
import { IStatus } from "../../../../shared/models/status";
import { ITask, ITaskHistory, Task, TaskComment, TaskHistory } from "../../../../shared/models/task";
import { IUserGroup } from "../../../../shared/models/userGroup";
import { IUserInfo } from "../../../../shared/models/userInfo";
import { checkPermissionChangeTask } from "../../../../shared/permissions";
import { compareArray } from "../../../../shared/utils";
import { checkRequirementsChangeStatusField, formatDate, isAdmin } from "../../../../util";
// import socket from "../../../../util/socket";
import Url from "../../../../util/url";
import EditorWidget from "../../../ckeditor/editor-widget";
import { checkAllChildTasksAreDone } from "../../Column";
import MyTextBox from "../../widgets/MyTextBox";
import SelectCampaignWidget from "../select-campaign";
import SubTasksPreview from "../sub-tasks-preview/index";
import CommentPanel from "./comment";
import ConfirmedPoints, { PointProps } from "./confirmed-points";
// import FollowersWidget from "./followers-task";
import Checkbox from "@mui/material/Checkbox";
import HistoryWidget from "./history";
import "./index.scss";
import SelectProjectWidget from "./select-project";
import SelectSprintWidget from "./select-sprint";
import SelectUserWidget from "./select-user";
import "./style.scss";
import ModalMoveSubtask from "./move-subtask";

interface Props {
    onOpenTestCase: () => void;
    task: ITask;
    onHide: () => void;
}

export const TASK_FIELDS = {
    assignee: "assignee",
    project: "project",
    sprint: "sprint",
    status: "status",
    title: "title",
    description: "description",
    reporter: "reporter",
    deadline: "deadline",
    priority: "priority",
    followers: "followers",
    testLink: "testLink",
    failedReason: "failedReason",
    confirmedPoints: "confirmedPoints",
    expectPoints: "expectPoints",
    consumedPoints: "consumedPoints",
    leadDev: "leadDev",
    inform: "inform",
    tester: "tester",
    point: "point",
    coef: "coefficient",
    checkPoint: "checkPoint",
};

type Field =
    | "assignee"
    | "project"
    | "sprint"
    | "status"
    | "title"
    | "description"
    | "reporter"
    | "deadline"
    | "priority"
    | "followers"
    | "testLink"
    | "failedReason"
    | "confirmedPoints"
    | "consumedPoints"
    | "expectPoints"
    | "leadDev"
    | "inform"
    | "tester"
    | "point"
    | "coef"
    | "checkPoint";

const NewEditTaskModal: FC<Props> = ({ onOpenTestCase, onHide, task }) => {
    // const [saving, setSaving] = useState<SaveStatus>("normal");
    const savingStateRef = useRef<HTMLSpanElement | null>(null);
    const tasks: Task[] = useSelector((state: any) => state.taskState.tasks ?? []);
    const listSubtask: Task[] = tasks.filter((t) => t.parentId === task._id);
    //*CHU Y: sau them socket thi phai check bo sung phan nay
    const processing: boolean = useSelector((state: any) => state.taskState.processing ?? false);
    const projects: IProject[] = useSelector((state: any) => state.projectState.projects ?? []);
    const users: IUserInfo[] = useSelector((state: any) => state.userInfoState.userInfos ?? []);
    const statuses: IStatus[] = useSelector((state: any) => state.statusState.status ?? []);
    const sprints: ISprint[] = useSelector((state: any) => state.sprintState.sprints ?? []);
    const userInfo: IUserInfo = useSelector((state: any) => state.authState.user);
    const userGroups: IUserGroup[] = useSelector((state: any) => state.userGroupState.userGroups);
    const mapUser: Map<string, IUserInfo> = new Map();
    for (let user of users) {
        mapUser.set(user._id, user);
    }
    const mapStatuses: Map<string, IStatus> = new Map();
    for (let status of statuses) {
        mapStatuses.set(status._id, status);
    }
    const mapProject: Map<string, IProject> = new Map();
    for (let project of projects) {
        mapProject.set(project._id, project);
    }
    const mapSprint: Map<string, ISprint> = new Map();
    let activeSprint = null;
    for (let sprint of sprints) {
        mapSprint.set(sprint._id, sprint);
        if (sprint.activeSprint) activeSprint = sprint;
    }
    if (!activeSprint) activeSprint = sprints[0];
    const parentTask: ITask | undefined = task.parent ?? tasks.find((t) => t._id == task.parentId);
    // const showSubTasks = tasks.findIndex(t => t.parentId == task._id) > -1;
    const showSubTasks = true;
    const [title, setTitle] = useState(task.title);
    const [testLink, setTestLink] = useState(task.testLink);
    const [statusId, setStatusId] = useState(task.statusId);
    const [priority, setPriority] = useState(task.priority);
    const [coef, setCoef] = useState(task.coef);
    const [deadline, setDeadline] = useState(task.deadline);
    const [projectId, setProjectId] = useState(task.projectId);
    const [assigneeId, setAssigneeId] = useState(task.assigneeId);
    const [reporterId, setReporterId] = useState(task.reporterId);
    const [sprintId, setSprintId] = useState(task.sprintId);
    const [description, setDescription] = useState(task.description);
    const [testerIds, setTesterIds] = useState(task.testerIds ? [...task.testerIds] : []);
    const [informIds, setInformIds] = useState(JSON.parse(JSON.stringify(task.inform ?? [])));
    const [leadDevId, setLeadDevId] = useState(task.leadDevId ?? "");
    const [isShowModalMove, setIsShowModalMove] = useState(false);
    // const [followerIds, setFollowerIds] = useState(task.followerIds ? [...task.followerIds] : []);
    const [deadlineTmp, setDeadlineTmp] = useState(task.deadline);
    const [points, setPoints] = useState<PointProps>({
        point: task.point,
        confirmedPoint: task.confirmedPoints,
        consumedPoint: task.consumedPoints,
        failedReason: task.failedReason,
        expectPoints: task.leadDevPoints
    });
    const [checkPoint, setCheckPoint] = useState(task?.checkPoint);
    const defaultProject = projects.find((p) => p._id == task.projectId);
    const dispatch = useDispatch();
    const actor = useSelector((state: any) => state.taskState.actor);
    // useEffect(() => {
    //     socket.on("comment", (args: any) => {
    //         let { response } = args.response;
    //         if (response) {
    //             let comment = new TaskComment(response);
    //             dispatch(updateCommentSuccess([comment]));
    //         }
    //     });

    //     socket.on("delete-comment", (args: any) => {
    //         let { commentId } = args;
    //         dispatch(deleteCommentSuccess([commentId]));
    //     });
    // }, []);
    useEffect(() => {
        /// for listener socket
        setTitle(task.title);
        setTestLink(task.testLink);
        setStatusId(task.statusId);
        setPriority(task.priority);
        setDeadline(task.deadline);
        setProjectId(task.projectId);
        setAssigneeId(task.assigneeId);
        setReporterId(task.reporterId);
        setSprintId(task.sprintId);
        setDescription(task.description);
        setTesterIds(task.testerIds ? [...task.testerIds] : []);
        setInformIds(JSON.parse(JSON.stringify(task.inform ?? [])));
        setLeadDevId(task.leadDevId ?? "");
        // setFollowerIds(task.followerIds ? [...task.followerIds] : []);
        setPoints({
            point: task.point,
            confirmedPoint: task.confirmedPoints,
            consumedPoint: task.consumedPoints,
            failedReason: task.failedReason,
            expectPoints: task.leadDevPoints
        });
        setCoef(task.coef);
        setCheckPoint(task.checkPoint);
    }, [task]);
    const deleteTask = async () => {
        if (checkPermissionDeleteTask(task, userInfo)) {
            if (window.confirm("Bạn chắc chắn muốn xoá task này chứ?")) {
                onHide();
                let listDeleteTask = getChildTasks(task, tasks);
                listDeleteTask.push(task);
                await Promise.all(
                    listDeleteTask.map(async (t) => {
                        let res = await axios
                            .patch(Url("task/" + t._id), {
                                statusView: -1,
                                deletedBy: userInfo._id,
                            })
                            .then(() => {
                                dispatch(deleteTaskAction(t._id));
                            })
                            .catch((e) => console.log(e));
                        return res;
                    })
                );
            }
        } else
            toast(() => <div>Bạn không có quyền xoá task này</div>, {
                position: "top-center",
                type: "error",
            });
    };

    const getChangedInfo = (newTask: ITask) => {
        let changes: ITaskHistory[] = [];
        const time = new Date();
        const userID = userInfo?._id;
        if (task.title !== newTask.title) {
            changes.push(
                new TaskHistory({
                    field: TASK_FIELDS.title,
                    from: task.title,
                    to: newTask.title,
                    userID,
                    time,
                })
            );
        }
        if (task.description !== newTask.description) {
            changes.push(
                new TaskHistory({
                    field: TASK_FIELDS.description,
                    from: task.description.substring(0, 100) + "...",
                    to: newTask.description.substring(0, 100) + "...",
                    userID,
                    time,
                })
            );
        }
        if (task.statusId !== newTask.statusId) {
            changes.push(
                new TaskHistory({
                    field: TASK_FIELDS.status,
                    from: task.statusId,
                    to: newTask.statusId,
                    userID,
                    time,
                })
            );
        }
        if (task.assigneeId != newTask.assigneeId) {
            changes.push(
                new TaskHistory({
                    field: TASK_FIELDS.assignee,
                    from: task.assigneeId,
                    to: newTask.assigneeId,
                    userID,
                    time,
                })
            );
        }
        if (task.reporterId !== newTask.reporterId) {
            changes.push(
                new TaskHistory({
                    field: TASK_FIELDS.reporter,
                    from: task.reporterId,
                    to: newTask.reporterId,
                    userID,
                    time,
                })
            );
        }
        if (task.deadline?.getTime() !== newTask.deadline?.getTime()) {
            changes.push(
                new TaskHistory({
                    field: TASK_FIELDS.deadline,
                    from: task.deadline,
                    to: newTask.deadline,
                    userID,
                    time,
                })
            );
        }
        if (task.priority !== newTask.priority) {
            changes.push(
                new TaskHistory({
                    field: TASK_FIELDS.priority,
                    from: task.priority,
                    to: newTask.priority,
                    userID,
                    time,
                })
            );
        }
        if (task.point !== newTask.point) {
            changes.push(
                new TaskHistory({
                    field: TASK_FIELDS.point,
                    from: task.point?.toString(),
                    to: newTask.point?.toString(),
                    userID,
                    time,
                    sendedMessage: true,
                })
            );
        }
        if (task.projectId !== newTask.projectId) {
            changes.push(
                new TaskHistory({
                    field: TASK_FIELDS.project,
                    from: task.projectId,
                    to: newTask.projectId,
                    userID,
                    time,
                    sendedMessage: true,
                })
            );
        }
        if (task.sprintId !== newTask.sprintId) {
            changes.push(
                new TaskHistory({
                    field: TASK_FIELDS.sprint,
                    from: task.sprintId,
                    to: newTask.sprintId,
                    userID,
                    time,
                })
            );
        }
        if (compareArray(task.testerIds, newTask.testerIds) != 0) {
            changes.push(
                new TaskHistory({
                    field: TASK_FIELDS.tester,
                    from: task.testerIds.join(","),
                    to: newTask.testerIds.join(","),
                    userID,
                    time,
                })
            );
        }
        if (compareArray(task.inform, newTask.inform) != 0) {
            changes.push(
                new TaskHistory({
                    field: TASK_FIELDS.inform,
                    from: task.inform.join(","),
                    to: newTask.inform.join(","),
                    userID,
                    time,
                })
            );
        }
        if (task.leadDevId !== newTask.leadDevId) {
            changes.push(
                new TaskHistory({
                    field: TASK_FIELDS.leadDev,
                    from: task.leadDevId,
                    to: newTask.leadDevId,
                    userID,
                    time,
                })
            );
        }
        if (task.consumedPoints !== newTask.consumedPoints) {
            changes.push(
                new TaskHistory({
                    field: TASK_FIELDS.consumedPoints,
                    from: task.consumedPoints?.toString(),
                    to: newTask.consumedPoints?.toString(),
                    userID,
                    time,
                    sendedMessage: true,
                })
            );
        }
        if (task.confirmedPoints !== newTask.confirmedPoints) {
            changes.push(
                new TaskHistory({
                    field: TASK_FIELDS.confirmedPoints,
                    from: task.confirmedPoints?.toString(),
                    to: newTask.confirmedPoints?.toString(),
                    userID,
                    time,
                })
            );
        }
        if (task.failedReason !== newTask.failedReason) {
            changes.push(
                new TaskHistory({
                    field: TASK_FIELDS.failedReason,
                    from: task.failedReason,
                    to: newTask.failedReason,
                    userID,
                    time,
                })
            );
        }
        if (task.leadDevPoints !== newTask.leadDevPoints) {
            changes.push(
                new TaskHistory({
                    field: TASK_FIELDS.expectPoints,
                    from: task.leadDevPoints,
                    to: newTask.leadDevPoints,
                    userID,
                    time,
                })
            );
        }
        if (task.testLink !== newTask.testLink) {
            changes.push(
                new TaskHistory({
                    field: TASK_FIELDS.testLink,
                    from: task.testLink,
                    to: newTask.testLink,
                    userID,
                    time,
                    sendedMessage: true,
                })
            );
        }
        if (task.coef !== newTask.coef) {
            changes.push(
                new TaskHistory({
                    field: TASK_FIELDS.coef,
                    from: task.coef,
                    to: newTask.coef,
                    userID,
                    time,
                    sendedMessage: true,
                })
            );
        }
        if (task.checkPoint !== newTask.checkPoint) {
            changes.push(
                new TaskHistory({
                    field: TASK_FIELDS.checkPoint,
                    from: task.checkPoint,
                    to: newTask.checkPoint,
                    userID,
                    time,
                    sendedMessage: true,
                })
            );
        }
        return changes;
    };

    const getListUser = (ids: string[]) => {
        const users: IUserInfo[] = [];
        for (let id of ids) {
            const user = mapUser.get(id);
            user && users.push(user);
        }
        return users;
    };

    const getTaskUpdate = (): ITask => {
        let cloneTask = JSON.parse(JSON.stringify(task));
        let taskUpdated = {
            ...cloneTask,
            confirmedPoints: points.confirmedPoint,
            consumedPoints: points.consumedPoint,
            failedReason: points.failedReason,
            leadDevPoints: points.expectPoints,
            point: points.point,
            deadline: deadline,
            description,
            // followerIds,
            // followers: getListUser(followerIds),
            inform: informIds,
            leadDevId,
            leadDev: mapUser.get(leadDevId),
            priority: priority,
            projectId,
            project: mapProject.get(projectId),
            sprintId,
            sprint: mapSprint.get(sprintId),
            statusId: statusId,
            status: mapStatuses.get(statusId),
            assigneeId,
            assignee: mapUser.get(assigneeId),
            reporterId,
            reporter: mapUser.get(reporterId),
            testerIds,
            testers: getListUser(testerIds),
            testLink: testLink,
            title: title,
            coef: coef,
        };
        if ((task.status?.shortId ?? 0) >= 4) taskUpdated = { ...taskUpdated, checkPoint: checkPoint }; // chỉ check khi nằm từ TO_DEPLOY (tránh trường hợp update 2 lần)
        // chỉ cần check ở đây thôi vì tại server đã xử lý gán = false khi status < TO_DEPLOY rồi, và cũng chỉ ở đây mới có giao diện thay đổi giá trị checkPoint,
        // các chỗ khác không cần check vì đã xử lý trên server
        return taskUpdated;
    };
    const onResetUpdate = (changes: ITaskHistory[]) => {
        for (let change of changes) {
            switch (change.field) {
                case TASK_FIELDS.status:
                    setStatusId(task.statusId);
                    break;
                case TASK_FIELDS.title:
                    setTitle(task.title);
                    break;
                case TASK_FIELDS.description:
                    setDescription(task.description);
                    break;
                case TASK_FIELDS.assignee:
                    setAssigneeId(task.assigneeId);
                    break;
                case TASK_FIELDS.reporter:
                    setReporterId(task.reporterId);
                    break;
                case TASK_FIELDS.deadline:
                    setDeadline(task.deadline);
                    break;
                case TASK_FIELDS.priority:
                    setPriority(task.priority);
                    break;
                case TASK_FIELDS.project:
                    setProjectId(task.projectId);
                    break;
                case TASK_FIELDS.sprint:
                    setSprintId(task.sprintId);
                    break;
                case TASK_FIELDS.tester:
                    setTesterIds(task.testerIds);
                    break;
                case TASK_FIELDS.inform:
                    setInformIds(task.inform);
                    break;
                case TASK_FIELDS.leadDev:
                    setLeadDevId(task.leadDevId);
                    break;
                case TASK_FIELDS.testLink:
                    setTestLink(task.testLink);
                    break;
                case TASK_FIELDS.coef:
                    setCoef(task.coef);
                    break;
                case TASK_FIELDS.checkPoint:
                    setCheckPoint(task.checkPoint);
                    break;
                default:
                    break;
            }
            if (
                change.field == TASK_FIELDS.point ||
                change.field == TASK_FIELDS.consumedPoints ||
                change.field == TASK_FIELDS.confirmedPoints ||
                change.field == TASK_FIELDS.failedReason
            ) {
                setPoints({
                    point: task.point,
                    confirmedPoint: task.confirmedPoints,
                    consumedPoint: task.consumedPoints,
                    failedReason: task.failedReason,
                    expectPoints: task.leadDevPoints
                });
            }
        }
    };
    const isLeaderGroup = (userId: string) => {
        let res = false;
        userGroups.forEach((group) => {
            group.users.forEach((user) => {
                if (user.userId === userId && user.role === "lead") {
                    if (task.projectId === group.projectId)
                        //la lead cua group thuoc project task
                        res = true;
                }
            });
        });
        return res;
    };

    const taskUpdate = getTaskUpdate();
    const changes = getChangedInfo(taskUpdate);
    const changesStr = changes.map((c) => c.field + c.from + c.to).toString();
    useEffect(() => {
        if (changes.length) {
            if (savingStateRef?.current) {
                savingStateRef.current.innerHTML = " Saving...";
            }
            dispatch(pendingProcessingTaskUpdate());
            setTimeout(() => {
                const histories = (changes ?? []).concat(task.histories ?? []);
                const newTask: ITask = {
                    ...taskUpdate,
                    histories,
                };
                let allowChangeAssignee = false;
                if (task.assigneeId !== newTask.assigneeId) {
                    //assignee cua task cha co the sua duoc assignee cua task con
                    let _task = task;
                    while (true) {
                        let parentId = _task.parentId;
                        let _pTask = tasks.find((t) => t._id === parentId);
                        if (!_pTask) break;
                        else {
                            if (_pTask?.assigneeId === userInfo._id) {
                                allowChangeAssignee = true;
                            }
                            _task = _pTask;
                        }
                    }
                }
                if (
                    allowChangeAssignee ||
                    checkPermissionChangeTask({
                        user: userInfo,
                        task: task,
                        newTask,
                        onFeedBack: (msg) => {
                            onResetUpdate(changes);
                            toast(() => <div>{msg}</div>, {
                                position: "top-center",
                                type: "error",
                            });
                            if (savingStateRef?.current) {
                                savingStateRef.current.innerHTML = "";
                            }
                            dispatch(updateTaskFailed(""));
                        },
                    })
                ) {
                    let updateStatus = !!changes.find((c) => c.field === "status");
                    let updateSprint = !!changes.find((c) => c.field === "sprint");
                    console.log('newTask', newTask);
                    
                    dispatch(
                        updateTask({
                            ...newTask,
                            updateStatus: updateStatus,
                            updateSprint: updateSprint,
                        })
                    );
                    if (savingStateRef?.current) {
                        savingStateRef.current.innerHTML = " Saved";
                    }
                    setTimeout(() => {
                        if (savingStateRef?.current) {
                            savingStateRef.current.innerHTML = "";
                        }
                    }, 1000);
                }
            }, 100);
        }
    }, [changesStr]);
    const setField = (field: Field, value: any) => {
        // dispatch(updateActorChangeTask(MY_SELF));
        switch (field) {
            case "title":
                setTitle(value);
                break;
            case "description":
                setDescription(value);
                break;
            case "testLink":
                setTestLink(value);
                break;
            case "status":
                setStatusId(value);
                break;
            case "project":
                setProjectId(value);
                break;
            case "sprint":
                setSprintId(value);
                break;
            case "assignee":
                setAssigneeId(value);
                break;
            case "reporter":
                setReporterId(value);
                break;
            case "tester":
                setTesterIds(value);
                break;
            case "leadDev":
                setLeadDevId(value);
                break;
            case "inform":
                setInformIds(value);
                break;
            case "point":
                setPoints(value);
                break;
            case "priority":
                setPriority(value);
                break;
            case "deadline":
                setDeadline(value);
                break;
            // case "followers":
            //     setFollowerIds(value);
            //     break;
            case "coef":
                setCoef(value);
                break;
            case "checkPoint":
                setCheckPoint(value);
                break;
            default:
                break;
        }
    };
    const onHideModalMove = () => {
        setIsShowModalMove(false);
    };
    const [checkboxValues, setCheckboxValues] = useState<{ [key: string]: boolean }>({});
    const [selectedSubtaskIds, setSelectedSubtaskIds] = useState<string[]>([]);
    const [selectAll, setSelectAll] = useState<boolean>(false);
    const handleCheckboxChange = (subtaskId: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        if (subtaskId && subtaskId !== "") {
            const { checked } = event.target;
            setCheckboxValues((prevState) => ({
                ...prevState,
                [subtaskId]: checked,
            }));

            if (checked) {
                setSelectedSubtaskIds((prevIds) => [...prevIds, subtaskId]);
            } else {
                setSelectedSubtaskIds((prevIds) => prevIds.filter((id) => id !== subtaskId));
            }
        } else {
            for(let subTask of listSubtask){
                setCheckboxValues((prevState) => ({
                    ...prevState,
                    [subTask._id]: false,
                }));
            }
            
        }
    };
    const handleSelectAllChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { checked } = event.target;
        setSelectAll(checked);
        const updatedCheckboxValues = { ...checkboxValues };
        listSubtask.forEach((subtask) => {
            updatedCheckboxValues[subtask._id] = checked;
        });
        setCheckboxValues(updatedCheckboxValues);
        if (checked) {
            setSelectedSubtaskIds(listSubtask.map((subtask) => subtask._id));
        } else {
            setSelectedSubtaskIds([]);
        }
    };
    const clearDataSelect = () => {
        setCheckboxValues({});
        setSelectedSubtaskIds([]);
        setSelectAll(false);
      };
    return (
        <div className="edit-tasks-container">
            <Modal show={true} size="xl" centered onHide={onHide}>
                <Modal.Header closeButton>
                    <Modal.Title style={{ width: "calc(100% - 32px)" }}>
                        <TaskTitle task={task} parentTask={parentTask} stateRef={savingStateRef} />
                    </Modal.Title>
                </Modal.Header>
                <ModalBody>
                    <div className="edit-task-modal-scroll">
                        <Grid container className="edit-task-modal" spacing={6}>
                            <Grid item xs={12} sm={12} md={7}>
                                <div className="item title">
                                    {(task.status?.shortId ?? 0) >= 4 &&
                                        (userInfo.role === Config.USER_ROLE_ADMIN ||
                                            !!task.testerIds.includes(userInfo._id)) && ( // từ TO_DEPLOY thì hiện nut này cho check
                                            <div className="align-item-center" style={{ color: "rgb(75, 180, 237)" }}>
                                                <Checkbox
                                                    disabled={processing}
                                                    checked={checkPoint}
                                                    onChange={(e) => {
                                                        setField("checkPoint", !checkPoint);
                                                    }}
                                                />
                                                <h5>Check Point</h5>
                                            </div>
                                        )}
                                    <h5>Title</h5>
                                    <MyTextBox
                                        text={title}
                                        title="title"
                                        disabled={
                                            // task.status?._id === Config.COLUMN_STATUS.DONE.mongoId ||
                                            // task.status?._id === Config.COLUMN_STATUS.PROD_VALIDATION.mongoId ||
                                            processing
                                        }
                                        onChange={(v) => {
                                            setField("title", v);
                                        }}
                                    />
                                </div>
                                <div className="item description">
                                    <div className="description-line-1">
                                        <h5>Description</h5>
                                    </div>
                                    <EditorWidget
                                        defaultValue={description}
                                        onChange={(v) => {
                                            setField("description", v);
                                        }}
                                        disabled={processing}
                                    />
                                </div>
                                <div className="item item-inline testLink">
                                    <h5>Test Link</h5>
                                    <MyTextBox
                                        text={testLink}
                                        disabled={processing}
                                        title="testLink"
                                        onChange={(v) => {
                                            setField("testLink", v);
                                        }}
                                    />
                                </div>
                                {/* {projectId && (
                                    <GitHubApps
                                        projectId={projectId}
                                        task={task}
                                    />
                                )} */}
                                {showSubTasks && (
                                    <div className="item subtasks" style={{ paddingTop: 16 }}>
                                        <SubTasksPreview
                                            location="task-modal"
                                            parentTask={task}
                                            onSelectedTask={() => {}}
                                            onShowMoveTaskModal={(e) => setIsShowModalMove(e)}
                                            checkedItems={checkboxValues}
                                            onChange={handleCheckboxChange}
                                        />
                                    </div>
                                )}
                                <div className="item">&nbsp;</div>
                                <div className="item histories">
                                    <h5>History</h5>
                                    <HistoryWidget histories={task.histories ?? []} />
                                </div>
                                <div className="item comments">
                                    <h5>Comments</h5>
                                    <CommentPanel task={task} />
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={5}>
                                <div className="item item-inline status">
                                    <h5>Status</h5>
                                    <Form.Select
                                        value={statusId}
                                        disabled={processing}
                                        onChange={(e) => {
                                            if (actor === MY_SELF) {
                                                if (!checkRequirementsChangeStatusField(task, e.target.value)) return;
                                                //check task con da done het chua thi moi duoc keo sang done
                                                //khi thay doi task cha thi can check de xem cos thay doi task con hay khong
                                                let allowEdit = true;
                                                if (e.target.value === Config.COLUMN_STATUS.DONE.mongoId) {
                                                    allowEdit = checkAllChildTasksAreDone(task, tasks);
                                                }
                                                if (allowEdit) setField("status", e.target.value);
                                            } else setField("status", e.target.value);
                                        }}
                                    >
                                        {statuses.map((status) => (
                                            <option value={status._id} key={status._id}>
                                                {status.title.toUpperCase()}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </div>
                                <div className="item item-inline project">
                                    <h5>Projects</h5>
                                    <SelectProjectWidget
                                        disabled={processing}
                                        ids={[projectId]}
                                        onSelected={(value) => {
                                            setField("project", value[0]);
                                        }}
                                    />
                                </div>
                                <div className="item item-inline sprint">
                                    <h5>Sprint</h5>
                                    <SelectSprintWidget
                                        sprintId={sprintId}
                                        onChange={(v) => {
                                            setField("sprint", v);
                                        }}
                                        disabled={processing}
                                    />
                                </div>
                                <div className="item item-inline assignee">
                                    <h5>Assignee</h5>
                                    <SelectUserWidget
                                        ids={[assigneeId]}
                                        onSelected={(value) => {
                                            setField("assignee", value[0]);
                                        }}
                                        disabled={processing}
                                    />
                                </div>
                                <div className="item item-inline reporter">
                                    <h5>Reporter</h5>
                                    <SelectUserWidget
                                        ids={[reporterId]}
                                        onSelected={(value) => {
                                            setField("reporter", value[0]);
                                        }}
                                        require={true}
                                        disabled={processing}
                                    />
                                </div>
                                <div className="item item-inline tester">
                                    <h5>Tester</h5>
                                    <SelectUserWidget
                                        isMulti={true}
                                        ids={testerIds}
                                        onSelected={(value) => {
                                            setField("tester", value);
                                        }}
                                        disabled={processing}
                                    />
                                </div>
                                <div className="item item-inline leaddev">
                                    <h5>Lead Dev</h5>
                                    <SelectUserWidget
                                        ids={[leadDevId]}
                                        onSelected={(value) => setField("leadDev", value[0])}
                                        disabled={processing}
                                    />
                                </div>
                                <div className="item item-inline inform">
                                    <h5>Inform</h5>
                                    <SelectUserWidget
                                        isMulti={true}
                                        ids={informIds}
                                        onSelected={(value) => setField("inform", value)}
                                        disabled={processing}
                                    />
                                </div>
                                <div className="item item-inline campaign">
                                    <h5>Campaigns</h5>
                                    <SelectCampaignWidget task={task} />
                                </div>
                                <ConfirmedPoints
                                    points={points}
                                    onChange={(v) => {
                                        setField("point", v);
                                    }}
                                    user={userInfo}
                                    disable={
                                        !(
                                            isLeaderGroup(userInfo._id) || //leader group
                                            task?.reporterId === //reporter
                                                userInfo._id ||
                                            userInfo.role === //leader all
                                                Config.USER_ROLE_LEADER ||
                                            userInfo.role === //leader all
                                                Config.USER_ROLE_ADMIN
                                        ) || processing
                                    }
                                />
                                <div className="item item-inline priority">
                                    <h5>Priority</h5>
                                    {userInfo._id === defaultProject?.ownerID ||
                                    isAdmin(userInfo) ||
                                    task.reporterId === userInfo._id ? (
                                        <Form.Select
                                            value={priority}
                                            onChange={(e) => setField("priority", e.target.value)}
                                            disabled={processing}
                                        >
                                            {["", ...Object.values(Config.PRIORITY_POOL)].map((p, index) => (
                                                <option key={index} value={p}>
                                                    {p}
                                                </option>
                                            ))}
                                        </Form.Select>
                                    ) : (
                                        <div className="cant-edit-priority">{task.priority}</div>
                                    )}
                                </div>
                                <div className="item item-inline deadline">
                                    <h5>Deadline</h5>
                                    <FormControl
                                        type="datetime-local"
                                        disabled={
                                            processing ||
                                            ((deadline != null || deadline != undefined
                                                ? !(
                                                      isLeaderGroup(userInfo._id) || //leader group
                                                      task?.reporterId === //reporter
                                                          userInfo._id ||
                                                      userInfo.role === //leader all
                                                          Config.USER_ROLE_LEADER ||
                                                      userInfo.role === //leader all
                                                          Config.USER_ROLE_ADMIN
                                                  )
                                                : false) &&
                                                task.type.toLowerCase() !== "sub task")
                                        }
                                        name="deadline"
                                        value={moment(deadlineTmp).format("YYYY-MM-DDTHH:mm")}
                                        min={moment(new Date().setHours(0, 0, 0, 0)).format("YYYY-MM-DDTHH:mm")}
                                        max={moment(activeSprint.endDate.setHours(23, 59, 59, 0)).format("YYYY-MM-DDTHH:mm")}
                                        onChange={(e) => {
                                            setDeadlineTmp(new Date(e.target.value));
                                        }}
                                        onBlur={(e) => {
                                            setField("deadline", deadlineTmp);
                                        }}
                                    />
                                </div>
                                <div className="item item-inline coef">
                                    <h5>Coefficient</h5>
                                    <Form.Select
                                        value={coef}
                                        disabled={
                                            !(
                                                isLeaderGroup(userInfo._id) || //leader group
                                                userInfo.role === //leader all
                                                    Config.USER_ROLE_LEADER ||
                                                userInfo.role === //leader all
                                                    Config.USER_ROLE_ADMIN
                                            ) || processing
                                        }
                                        onChange={(e) => setField("coef", parseFloat(e.target.value))}
                                    >
                                        {Object.values(Config.COEF).map((p, index) => (
                                            <option key={index} value={p}>
                                                {p}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </div>

                                <div
                                    className="test-modal"
                                    style={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                    }}
                                >
                                    <ButtonMUI variant="text" color="warning" onClick={onOpenTestCase}>
                                        Open Test Case
                                    </ButtonMUI>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <CreatedUser user={task.createdUserId ? mapUser.get(task.createdUserId) : undefined} task={task} />
                    <Button
                        onClick={() => {
                            deleteTask();
                        }}
                        style={{
                            background: "#ed3f3f",
                            borderColor: "#ed3f3f",
                        }}
                        disabled={processing}
                    >
                        Xoá
                    </Button>
                    <Button className="close" aria-label="Close" onClick={onHide} variant="secondary">
                        Huỷ
                    </Button>
                </ModalFooter>
            </Modal>
            {isShowModalMove && (
                <ModalMoveSubtask
                    listSubtaskId={selectedSubtaskIds}
                    isSelectAll={selectAll}
                    handleSelectAllChange={handleSelectAllChange}
                    checkedItems={checkboxValues}
                    onChange={handleCheckboxChange}
                    tasks={tasks}
                    onHide={onHideModalMove}
                    listSubtask={listSubtask}
                    task={task}
                    clearDataSelect={clearDataSelect}
                />
            )}
        </div>
    );
};

const CreatedUser: FC<{ user?: IUserInfo; task: ITask }> = ({ user, task }) => {
    let info = "";
    if (user) info = `Created By ${user.firstName + " " + user.lastName}, `;
    if (task.createdTime) info += "Created at " + formatDate(new Date(task.createdTime), "dd/mm/yyyy") + ", ";
    if (task.finishedDate && task.finishedDate > -1) info += "Finished at " + new Date(task.finishedDate) + ", ";
    return (
        <div
            style={{
                fontSize: 12,
                fontStyle: "italic",
                marginRight: "auto",
            }}
        >
            {info}
        </div>
    );
};

const TaskTitle: FC<{
    task: ITask;
    parentTask: ITask | undefined;
    stateRef: any;
}> = ({ task, parentTask, stateRef }) => {
    return (
        <div>
            <div
                style={{
                    width: "100%",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                }}
            >
                {parentTask && (
                    <span className="task-parent">
                        <a href={"/task/" + parentTask._id}>{parentTask.shortId}</a>
                        <span>{">"}</span>
                    </span>
                )}
                <span>{task.shortId}: </span>
                <span>{task.title}</span>
                <span ref={stateRef} />
            </div>
        </div>
    );
};

const checkPermissionDeleteTask = (task: ITask, user: IUserInfo) => {
    if (task.createdUserId === user._id) return true;
    if (task.reporterId === user._id) return true;
    if (user.role === Config.USER_ROLE_ADMIN) return true;
    return false;
};
export default memo(NewEditTaskModal, (p, n) => {
    if (JSON.stringify(p.task)?.localeCompare(JSON.stringify(n.task)) != 0) return false;
    return true;
});
